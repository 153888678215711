import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Layout, Space, Typography } from 'antd'

const { Footer } = Layout
const { Text } = Typography

export const FooterLayout: React.FC = () => {
  const navigate = useNavigate()
  const year = new Date().getFullYear()

  return (
    <Footer style={{ textAlign: 'center', backgroundColor: '#f8f8f2' }}>
      <Space direction="vertical">
        <Space direction="horizontal" wrap style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={() => navigate('/legal-notice')}>
            <Text style={{ fontWeight: 300 }}>LEGACY.LEGAL_NOTICE</Text>
          </Button>
          <Button type="text" onClick={() => navigate('/data-privacy')}>
            <Text style={{ fontWeight: 300 }}>LEGACY.DATA_PRIVACY</Text>
          </Button>
        </Space>
        <Text style={{ fontWeight: 300 }}>© {year} Paris Society</Text>
      </Space>
    </Footer>
  )
}
