import { useEffect } from 'react'
import PageTitle from '../../components/utils/PageTitle'
import { Breadcrumb, Card } from 'antd'
import { useAppDispatch } from '../../utils/hooks'
import { getJobs } from '../../store/JobReducer'
import { initialQuery } from '../../utils/CRUDHelper'

const HomePage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log('coucou')
    dispatch(getJobs(initialQuery))
  }, [])
  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 0' }}
        items={[/* { title: 'Home' }, */ { title: 'Home Page' }]}
      />

      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        title={<PageTitle title="Home Page" level={5} />}
      ></Card>
    </>
  )
}

export default HomePage
