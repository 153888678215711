import { RootState } from './store'
import { User } from '../models/User'
import { createSlice } from '@reduxjs/toolkit'
import { baseReducers, SBState } from '../utils/ReducerHelper'
import { initialQuery } from '../utils/CRUDHelper'

const initialState: SBState<User> & { user: User | undefined } = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
  user: undefined,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    ...baseReducers,
    setLoggedUser(state, action) {
      state.isLoading = false
      state.user = { ...state.user, ...action.payload }
    },
  },
})

// Reducer
export default slice.reducer

export const {
  setLoggedUser,
  getItemsSuccess: getUsersSuccess,
  setQuery: setUserQuery,
  resetQueryAndIds: resetUserQueryAndIds,
} = slice.actions

/**
 * Selectors
 */
export const selectLoggedUser = (state: RootState): User | undefined =>
  state.user.user
