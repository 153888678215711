import { AxiosStatic } from 'axios'
import { msalInstance } from '../index'
import { loginRequest } from '../authConfig'

export function setupAxios(axios: AxiosStatic) {
  // Requests default headers
  axios.defaults.headers.common['Accept'] = 'application/json'

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      const account = msalInstance.getActiveAccount()
      if (!account) {
        throw Error(
          'No active account! Verify a user has been signed in and setActiveAccount has been called.'
        )
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      })

      axios.defaults.headers.common['Accept'] = 'application/json'
      axios.defaults.headers.common['Authorization'] =
        `Bearer ${response.idToken}`

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config

      // Handle 401 status code response
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        // Set the request as retried
        originalRequest._retry = true

        const account = msalInstance.getActiveAccount()
        if (!account) {
          throw Error(
            'No active account! Verify a user has been signed in and setActiveAccount has been called.'
          )
        }

        // Launch a SSO redirection to get a new token
        msalInstance.acquireTokenRedirect({
          ...loginRequest,
          account: account,
        })

        /**
         * Return the original
         * We actually might not reach this return as acquireTokenRedirect() will launch a redirection to Microsoft login page
         * After login, user will be redirected to /login-redirect
         */
        return axios(originalRequest)
      }

      return Promise.reject(error)
    }
  )
}

export interface MessageResponse {
  message: string
  details: any
}
export interface APIResponse<T> {
  status: number
  data: T
}
