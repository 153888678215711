import App from '../App'
import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

// Auth
import AuthLayout from '../components/layout/auth/AuthLayout'
import Login from '../pages/auth/Login'
import LoginRedirect from '../pages/auth/LoginRedirect'

// Admin
import LoggedLayout from '../components/layout/LoggedLayout'

// Public
import LegalNotice from '../pages/legal/LegalNotice'
import DataPrivacy from '../pages/legal/DataPrivacy'
import HomePage from '../pages/admin/HomePage'

const AppRoutes: FC = () => (
  <Routes>
    <Route element={<App />}>
      {/* Authentication routes */}
      <Route element={<AuthLayout />}>
        {/* MSAL Login redirect route */}
        <Route path="/login-redirect" element={<LoginRedirect />} />
        {/* Login page */}
        <Route path="/login" element={<Login />} />
      </Route>

      {/* Logged routes - Protected by SSO */}
      <Route element={<LoggedLayout />}>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        {/* Legal documents */}
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="/data-privacy" element={<DataPrivacy />} />
      </Route>

      {/* Fallback route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  </Routes>
)

export default AppRoutes
